<script>
import { sameAs } from 'vuelidate/lib/validators';
import BasePasswordInput from '@shared/components/BasePasswordInput.vue';

export default {
  components: { BasePasswordInput },
  i18n: {
    messages: {
      en: {
        reset_password_title: 'Password Reset',
        reset_password_heading: 'Change Password',
        new_password_label: 'New password',
        confirm_password_label: 'Confirm password',
        passwords_not_match: 'Passwords do not match',
        password_placeholder: '(minimum 8 characters)',
        change_password_button: 'Change password',
        reset_password_success: 'Your password has been successfully changed!',
        reset_link_invalid: 'This reset link is not working or has expired.',
        reset_link_request_new: 'Please make a new forgotten password request.',
      },
      fr: {
        reset_password_title: 'Changement de mot de passe',
        reset_password_heading: 'Changer de mot de passe',
        new_password_label: 'Nouveau mot de passe',
        confirm_password_label: 'Confirmer le mot de passe',
        passwords_not_match: 'Les mots de passe ne correspondent pas',
        password_placeholder: '(8 caractères minimum)',
        change_password_button: 'Changer le mot de passe',
        reset_password_success: 'Votre mot de passe a bien été changé !',
        reset_link_invalid: 'Ce lien de réinitialisation ne fonctionne pas ou plus.',
        reset_link_request_new: 'Veuillez refaire une demande de mot de passe oublié.',
      },
    },
  },
  head() {
    return {
      title: this.$t('reset_password_title'),
    };
  },
  props: {
    to: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    isLinkValid: true,
    password: '',
    passwordConfirm: '',
  }),
  validations: {
    passwordConfirm: { sameAs: sameAs('password') },
  },
  methods: {
    handle() {
      this.isLoading = true;
      const { password } = this;
      const { token } = this.$route.query;
      this.$store.dispatch('auth/resetPassword', { password, token })
        .then(() => {
          this.$buefy.dialog.alert(this.$t('reset_password_success'));
          this.$router.push(this.to);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  created() {
    if (!this.$route.query.token) {
      this.isLinkValid = false;
    }
  },
};
</script>

<template>
  <div>
    <form
      v-if="isLinkValid"
      class="p-5"
      @submit.prevent="dataIsValid(isLoading) && handle()">
      <h1 class="title is-4">
        {{ $t('reset_password_heading') }}
      </h1>
      <b-field :label="$t('new_password_label')">
        <BasePasswordInput
          v-model="password"
        />
      </b-field>
      <b-field
        :label="$t('confirm_password_label')"
        v-bind="$getErrorProps($v.passwordConfirm, [{ name: 'isSame', message: $t('passwords_not_match') }])">
        <b-input
          v-model="passwordConfirm"
          type="password"
          :placeholder="$t('password_placeholder')"
          min="8"
          required
          password-reveal
        />
      </b-field>
      <div class="buttons">
        <b-button
          native-type="submit"
          type="is-primary is-custom"
          :loading="isLoading"
          expanded>
          {{ $t('change_password_button') }}
        </b-button>
      </div>
    </form>
    <div v-else class="content p-5">
      <p>
        {{ $t('reset_link_invalid') }}
      </p>
      <p>
        {{ $t('reset_link_request_new') }}
      </p>
    </div>
  </div>
</template>
